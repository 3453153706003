import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'setting-locales',
  data(){
    return {
      page: 1,
      filter: {},
    }
  },
  created(){
    this.fetchList();
  },
  computed: {
    ...mapGetters({
      list: 'settingLocales/list'
    })
  },
  methods: {
    ...mapActions({
      fetchList: 'settingLocales/GET_LIST'
    })
  }
}
